/** @format */

import React from 'react';

export default function PaymentHistoryRow({ id, date, name, price }) {
    return (
        <div className="payment_element">
            <span>{id}</span>
            <span>{name}</span>
            <span>{date}</span>
            <span>{price} ₽</span>
        </div>
    );
}
