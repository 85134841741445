/** @format */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: {
        id: null,
        avatar: null,
        username: null,
        token: null,
        uuid: null,
        phone: null,
        email: null,
        paymentOrderHistory: [],
        activeSubscription: {
            duration: null,
            amount: null,
        },
        nextPaymentDate: null,
        stopRecurrentCharges: false,
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
        },
        logout: (state = initialState, action) => {
            return initialState;
        },
        setProfileInfo: (state, action) => {
            state.user.paymentOrderHistory = action.payload.paymentOrderHistory;
            state.user.activeSubscription = action.payload.activeSubscription;
            state.user.nextPaymentDate = action.payload.nextPaymentDate;
            state.user.stopRecurrentCharges = action.payload.stopRecurrentCharges;
        },
    },
});

export const { login, logout, setProfileInfo } = userSlice.actions;

export default userSlice.reducer;
