/** @format */

export const AuthSettings = {
    VK_APP_ID: '51925588',
    VK_SERVICE_TOKEN:
        'd9c86a61d9c86a61d9c86a61e6dad03835dd9c8d9c86a61bf8f47d04b1c186db1004fdf',
    // REDIRECT_URL: 'https://127.0.0.1:3005/auth',
    REDIRECT_URL: 'https://flighthelperbot.com/auth',
    DISCORD_APP_ID: '727484772775100487',
};

export const discordAuthUrl = (state) => {
    const url = `https://discord.com/api/oauth2/authorize?client_id=${AuthSettings.DISCORD_APP_ID}&redirect_uri=${AuthSettings.REDIRECT_URL}&response_type=token&scope=identify email`;
    if (state) return url + `&state=${state}`;
    return url;
};
