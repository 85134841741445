/** @format */

import { useEffect } from 'react';
import { useParams } from 'react-router';

export default function OrdersRedirect() {
    const { order_id } = useParams();

    useEffect(() => {
        window.location.href =
            `https://yoomoney.ru/checkout/payments/v2/contract?orderId=${order_id}`;
    }, []);

    return null;
}
