/** @format */

import avatar from '../../../static/images/avatar.png';
import iconExit from '../../../static/images/iconExit.png';
import { logout } from '../userSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function LoginButton({ user, setUser }) {
    const dispatch = useDispatch();
    const stateUser = useSelector((state) => state.user.user);

    return (
        <div className="user_container">
            <img
                className="user_avatar"
                src={user?.avatar || avatar}
                alt="avatar"
            />
            <h3 className="user_name">{user?.username}</h3>
            <button
                className="exitbtn"
                onClick={() => {
                    dispatch(logout());
                    setUser(stateUser);
                }}
            >
                <img src={iconExit} alt="icon" />
                <p>Выход</p>
            </button>
        </div>
    );
}
