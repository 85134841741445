/** @format */
import pdf from '../../static/pdf/offer.pdf';
import { useTitle } from '../../utlis/hooks';

export default function Offer() {
    useTitle('Offer');
    return (
        <iframe
            title="Offer"
            src={pdf}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
                border: 'none',
                margin: 0,
                padding: 0,
                overflow: 'hidden',
                zIndex: 999999,
            }}
        >
            Ваш браузер не поддерживает фреймы
        </iframe>
    );
}
