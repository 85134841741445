/** @format */

import axios from 'axios';
import { message } from 'antd';
import { DurationConst } from '../utlis/constants/PlansConstants';
import { printError } from '../utlis/utils';

const instance = axios.create({
    withCredentials: true,
    baseURL: 'https://flighthelperbot.com/api/v1/',
});

type CreatePaymentType = {
    userId: string;
    amount: number;
    duration:
        | typeof DurationConst.MONTH_1
        | typeof DurationConst.MONTH_6
        | typeof DurationConst.MONTH_12;
    uuid: string;
    token: string;
    serviceToken: string;
    phone: string;
    email: string;
};

export const paymentAPI = {
    createPayment({
        userId,
        amount,
        duration,
        uuid,
        token,
        serviceToken,
        phone,
        email,
    }: CreatePaymentType): Promise<any> {
        return instance
            .get(`payments/get_url/`, {
                params: {
                    user_id: userId,
                    amount,
                    duration,
                    uuid,
                    token,
                    service_token: serviceToken,
                    phone,
                    email,
                },
            })
            .then((res) => {
                return res?.data;
            })
            .catch((error) => {
                printError(error);
                return error;
            });
    },
};

export const userAPI = {
    subscribersPayments({ userId }) {
        return instance
            .get(`subscribers/payments/`, {
                params: {
                    user_id: userId,
                },
            })
            .then((res) => {
                return res?.data;
            })
            .catch((error) => {
                printError(error);
                return error;
            });
    },
    rollbackPayment({ userId }) {
        return instance
            .post(`subscribers/payments/`, {
                user_id: userId,
            })
            .then((res) => {
                message.success('Подписка отменена');
                return res?.data;
            })
            .catch((error) => {
                printError(error);
                return error;
            });
    },
};
