/** @format */
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { paymentAPI } from '../../config/api.ts';
import { AmountConst } from '../../utlis/constants/PlansConstants';
import { AuthSettings } from '../../utlis/constants/AuthSettings';
import { Spin } from 'antd';
import { Navigate } from 'react-router-dom';
import { getStorageData } from '../../utlis/hooks';

export default function BuyPage() {
    const user = useSelector((state) => state.user.user);
    const duration = useSelector((state) => state.plans.duration);
    const email = getStorageData('email');

    useEffect(() => {
        if (user?.id && duration) {
            paymentAPI
                .createPayment({
                    userId: user.id,
                    amount: AmountConst[duration],
                    duration: duration,
                    uuid: user?.uuid,
                    token: user?.token,
                    serviceToken: AuthSettings.VK_SERVICE_TOKEN,
                    email: user?.email ? user.email : email,
                })
                .then((res) => {
                    localStorage.setItem('email', null);
                    if (res?.payment_url)
                        window.location.href = res?.payment_url;
                });
        }
    }, [user, duration, email]);

    return user?.id ? (
        <Spin fullscreen size="large" spinning={true}></Spin>
    ) : (
        <Navigate to={`/auth${duration ? '/' + duration : ''}`} />
    );
}
