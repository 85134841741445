/** @format */

import SubscriptionElement from './SubscriptionElement';

export default function Subscription() {
    return (
        <div className="subscription_container">
            <h1 className="subscription_title">
                подписка Pro для настоящих гиков
            </h1>
            <div className="subscription_info">
                <SubscriptionElement
                    type={'1'}
                    title="Свежие чарты"
                    description="Чарты обновляются раз в 2-3 неделю, с выходом нового цикла Джеппесен."
                />
                <SubscriptionElement
                    type={'2'}
                    title="Визуализация осадков"
                    description="На карте маршрута будут видны все погодные преграды, встречающиеся на пути."
                />
                <SubscriptionElement
                    type={'3'}
                    title="Диспетчеры IVAO/VATSIM"
                    description="Бот покажет все зоны контроля в сетях IVAO и VATSIM, которые встретятся во время полета."
                />
                <SubscriptionElement
                    type={'4'}
                    title="Картинка с рабочими ВПП"
                    description="Визуальное представление ветра поможет точнее спланировать взлёт или посадку."
                />
                <SubscriptionElement
                    type={'5'}
                    title="Экспорт маршрутов в .fms и .kml"
                    description="Легко переносите ваши маршруты в файлы формата .fms и .kml для большего удобства."
                />
                <SubscriptionElement
                    type={'6'}
                    title="Генерация лётных планов в IVAO/VATSIM"
                    description="Сократите время подготовки к виртуальному полёту, доверив заполнение лётного плана нашему боту."
                />
            </div>
        </div>
    );
}
