/** @format */

import React from 'react';
import {
    DurationTitleConst,
    MonthlyPriceTitleConst,
} from '../../../utlis/constants/PlansConstants';
import { userAPI } from '../../../config/api.ts';
import { useNavigate } from 'react-router-dom';

export default function UserSubscription({ user }) {
    const navigate = useNavigate();

    return (
        <div className="user_subscription_container">
            <h3>Подписка</h3>
            <h4>
                {user?.activeSubscription?.duration
                    ? `На ${DurationTitleConst[user.activeSubscription.duration]}`
                    : 'Отсутствует'}
            </h4>
            {user?.activeSubscription?.amount && (
                <p className="subscription_price">
                    {user.activeSubscription.amount} ₽{' '}
                    {MonthlyPriceTitleConst[user.activeSubscription.duration]}
                </p>
            )}
            <div className="buttons">
                {user?.activeSubscription?.duration ? (
                    <>
                        <button
                            className="changebtn"
                            onClick={() => navigate('/#plans')}
                        >
                            Изменить
                        </button>
                        <button
                            className="cancelbtn"
                            onClick={() => {
                                userAPI.rollbackPayment({
                                    userId: user?.id,
                                });
                            }}
                        >
                            Отменить
                        </button>
                    </>
                ) : (
                    <button
                        className="changebtn"
                        style={{ marginTop: '50px', width: '100%' }}
                        onClick={() => navigate('/#plans')}
                    >
                        Оформить подписку
                    </button>
                )}
            </div>
            {user?.nextPaymentDate && (
                <p className="next_payment">
                    Следующее списание произойдет {user?.nextPaymentDate}
                </p>
            )}
        </div>
    );
}
