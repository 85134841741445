import React from 'react'
import { useTitle } from '../utlis/hooks'
import { Link } from 'react-router-dom'
import Header from './Header';

export default function NotFoundPage() {
    useTitle('Not Found');
    return (
        <>
            <Header />
            <div className="not_found_page">
                <h1>404</h1>
                <p>Страница не найдена :(</p>
                <button>
                    <Link to="/">На главную</Link>
                </button>
            </div>
        </>
    )
}
