/** @format */

import Intro from './components/Intro';
import Cards from './components/Cards';
import MarketingText from './components/MarketingText';
import Subscription from './components/Subsription';
import { useAnchorScroll, useTitle } from '../../utlis/hooks';
import Plans from '../plans/components/Plans';

export default function MainPage() {
    useTitle('Home');
    useAnchorScroll();

    return (
        <>
            <Intro />
            <Cards />
            <MarketingText />
            <Subscription />
            <div id="plans"></div>
            <Plans />
        </>
    );
}
