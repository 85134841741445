/** @format */

import { useEffect } from 'react';

export default function DownloadVJoy() {
    useEffect(() => {
        window.location.href =
            'https://github.com/jshafer817/vJoy/releases/download/v2.1.9.1/vJoySetup.exe';
    }, []);

    return null;
}
