/** @format */

export default function Game() {
    return (
        <iframe
            id="inlineFrameExample"
            title="Inline Frame Example"
            width="100%"
            height="900"
            src="https://s-macke.github.io/FSHistory/"
        ></iframe>
    );
}
