/** @format */

import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { login } from '../pages/user/userSlice';
import { setPlan } from '../pages/mainPage/plansSlice';

export function useTitle(title) {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = 'FHB | ' + title;
        return () => {
            document.title = prevTitle;
        };
    });
}

export function useAnchorScroll() {
    const { hash, pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) element.scrollIntoView({ behavior: 'smooth' });
            navigate(pathname);
        }
    }, [hash, navigate, pathname]);
}

export const getStorageData = (keyName, defaultValue) => {
    const savedItem = localStorage.getItem(keyName);
    const parsedItem = JSON.parse(savedItem);
    return parsedItem || defaultValue;
};

export const useLocalStorage = (keyName, initialValue) => {
    const [value, setValue] = useState(() => {
        return getStorageData(keyName, initialValue);
    });

    useEffect(() => {
        localStorage.setItem(keyName, JSON.stringify(value));
    }, [keyName, value]);

    return [value, setValue];
};

export function useDiscordLogin() {
    const { hash } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tokenType, setTokenType] = useState();
    const [accessToken, setAccessToken] = useState();
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) setSearchParams(hash.slice(1));
    }, [hash, setSearchParams]);

    useEffect(() => {
        if (searchParams && !searchParams.get('payload')) {
            setTokenType(searchParams.get('token_type'));
            setAccessToken(searchParams.get('access_token'));
            setDuration(searchParams.get('state'));
        }
    }, [searchParams]);

    useEffect(() => {
        if (accessToken && tokenType) {
            setLoading(true);
            axios
                .get('https://discord.com/api/users/@me', {
                    headers: {
                        authorization: `${tokenType} ${accessToken}`,
                    },
                })
                .then((res) => {
                    dispatch(
                        login({
                            id: res?.data?.id,
                            avatar: res?.data?.avatar,
                            username: res?.data?.username,
                            email: res?.data?.email,
                            locale: res?.data?.locale,
                        })
                    );
                    if (duration) {
                        dispatch(setPlan(duration));
                        navigate('/buy');
                    } else navigate('/user');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [tokenType, accessToken, dispatch, duration, navigate]);

    return loading;
}

export function useVKLogin() {
    const [payload, setPayload] = useState(null);
    const [duration, setDuration] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.has('payload'))
            setPayload(JSON.parse(searchParams.get('payload')));
        if (searchParams.has('state')) setDuration(searchParams.get('state'));
    }, [searchParams]);

    useEffect(() => {
        if (payload) {
            dispatch(
                login({
                    id: payload.user.id,
                    avatar: payload.user.avatar,
                    username: `${payload.user.first_name} ${payload.user.last_name}`,
                    token: payload.token,
                    uuid: payload.uuid,
                    phone: payload.user.phone,
                })
            );
            if (duration) {
                dispatch(setPlan(duration));
                navigate('/buy');
            } else navigate('/user');
        }
    }, [payload, duration, dispatch, navigate]);
}

export function useFileDownloader(url, filename) {
    const navigate = useNavigate();

    useEffect(() => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        navigate('/');
    }, [url, filename, navigate]);
}
