/** @format */
import { Link } from 'react-router-dom';

export default function Intro() {
    return (
        <div className="intro">
            <h1>
                спланируем ваш <br /> виртуальный полет
            </h1>
            <p>
                Первый в мире бот-планировщик, который может <br /> рассчитать
                все необходимые данные
            </p>
            <button className="intro-button">
                <Link to="https://vk.me/metar_bot">Начать</Link>
            </button>
        </div>
    );
}
