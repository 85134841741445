/** @format */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    duration: null,
};

export const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setPlan: (state, action) => {
            state.duration = action.payload;
        },
    },
});

export const { setPlan } = plansSlice.actions;

export default plansSlice.reducer;
