/** @format */
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer>
            <div>
                <p>@ Flight Helper Bot. 2025</p>
                <br />
                <p>Арюков Никита Алексеевич ИНН: 540551409695</p>
                <p>support@flighthelperbot.com</p>
            </div>
            <ul>
                <li>
                    <Link to="/policy">Политика конфиденциальности</Link>
                    <span>·</span>
                </li>
                <li>
                    <Link to="/offer">Оферта</Link>
                    <span>·</span>
                </li>
                <li>
                    <Link to="https://vk.me/metar_bot">ВКонтакте</Link>
                    <span>·</span>
                </li>
                <li>
                    <Link to="https://t.me/flight_helper_bot">Telegram</Link>
                </li>
            </ul>
        </footer>
    );
}
