/** @format */
import React from 'react';
import { ConfigProvider, Spin } from 'antd';
import { useDiscordLogin, useTitle, useVKLogin } from '../../utlis/hooks';
import LoginForm from './components/LoginForm';
import Header from '../../components/Header';

export default function LoginPage() {
    useTitle('Auth');
    useVKLogin();
    const discordLoading = useDiscordLogin();

    return (
        <ConfigProvider
            theme={{
                components: {
                    Spin: {
                        contentHeight: '100%',
                    },
                },
            }}
        >
            <Spin size="large" spinning={discordLoading}>
                <Header />
                <LoginForm />
            </Spin>
        </ConfigProvider>
    );
}
