/** @format */

import icon1 from '../../../static/images/icon1.png';
import icon2 from '../../../static/images/icon2.png';
import icon3 from '../../../static/images/icon3.png';
import icon4 from '../../../static/images/icon4.png';
import icon5 from '../../../static/images/icon5.png';
import icon6 from '../../../static/images/icon6.png';

export default function SubscriptionElement({ type, title, description }) {
    const getIconByType = () => {
        switch (type) {
            case '1':
                return icon1;
            case '2':
                return icon2;
            case '3':
                return icon3;
            case '4':
                return icon4;
            case '5':
                return icon5;
            case '6':
                return icon6;
            default:
                return null;
        }
    };

    return (
        <div className={`info info${type}`}>
            <div className="info_title">
                <img src={getIconByType()} alt="icon" />
                <h6>{title}</h6>
            </div>
            <p>{description}</p>
        </div>
    );
}
