/** @format */

import React from 'react';
import screen1 from '../../../static/images/screen1.jpeg';
import screen2 from '../../../static/images/screen2.jpeg';

const { Fragment } = React;

export default function Card({ cardType, title, descriptionList = [] }) {
    const getImgByType = () => {
        switch (cardType) {
            case '1':
                return screen1;
            case '2':
                return screen2;
            default:
                return null;
        }
    };

    const getDescriptionText = () => {
        return (
            <p>
                {descriptionList?.map((el, i) => (
                    <Fragment key={i}>
                        {el}
                        <br />
                    </Fragment>
                ))}
            </p>
        );
    };

    return (
        <div className={`card card${cardType}`}>
            <h5>{title}</h5>
            {getDescriptionText()}
            <img id={`screen${cardType}`} src={getImgByType()} alt="VK" />
        </div>
    );
}
