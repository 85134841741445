import React from 'react'
import Plans from './components/Plans'
import { useTitle } from '../../utlis/hooks'

export default function PlansPage() {
    useTitle('Plans');
    return (
        <>
            <h1 className="plans_title">К сожалению, <br /> лимит на бесплатные чарты закончился :(</h1>
            <p className="plans_text">Оформите подписку, чтобы продолжить пользоваться чартами без ограничений</p>
            <Plans />
        </>
    )
}
