/** @format */
import { configureStore } from '@reduxjs/toolkit';
import plansReducer from '../pages/mainPage/plansSlice';
import userSlice from '../pages/user/userSlice';

export const store = configureStore({
    reducer: {
        plans: plansReducer,
        user: userSlice,
    },
});
