/** @format */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVk, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import * as VKID from '@vkid/sdk';
import img1 from '../../../static/images/side_image1.jpg';
import img2 from '../../../static/images/side_image2.jpg';
import img3 from '../../../static/images/side_image3.jpg';
import img4 from '../../../static/images/side_image4.jpg';
import img5 from '../../../static/images/side_image5.jpg';
import img6 from '../../../static/images/side_image6.jpg';
import img7 from '../../../static/images/side_image7.jpg';
import {
    AuthSettings,
    discordAuthUrl,
} from '../../../utlis/constants/AuthSettings';
import { useLocalStorage } from '../../../utlis/hooks';

export default function LoginForm() {
    const [currentImage, setCurrentImage] = useState('');
    const { pathname } = useLocation();
    const { duration } = useParams();
    // eslint-disable-next-line no-unused-vars
    const [email, setEmail] = useLocalStorage('email', null);

    const images = [img1, img2, img3, img4, img5, img6, img7];

    const getRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * images.length);
        return images[randomIndex];
    };

    useEffect(() => {
        const randomImage = getRandomImage();
        setCurrentImage(randomImage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="login_form_container">
                <form className="login_form" action="">
                    <h1>
                        Авторизуйтесь, <br /> чтобы мы вас <br /> узнали
                    </h1>
                    <button
                        id="VKIDSDKAuthButton"
                        className="vk_login"
                        onClick={(e) => {
                            e.preventDefault();
                            let config = {
                                app: AuthSettings.VK_APP_ID, // Идентификатор приложения.
                                redirectUrl: AuthSettings.REDIRECT_URL, // Адрес для перехода после авторизации.
                            };
                            if (duration) config.state = duration; // Произвольная строка состояния приложения.
                            VKID.Config.set(config);
                            // Открытие авторизации.
                            VKID.Auth.login();
                        }}
                    >
                        <FontAwesomeIcon className="vk_icon" icon={faVk} />
                        <p>Войти через ВКонтакте</p>
                    </button>
                    {/*<button*/}
                    {/*    className="vk_login"*/}
                    {/*    onClick={(e) => {*/}
                    {/*        e.preventDefault();*/}
                    {/*        window.location.href = discordAuthUrl(duration);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <FontAwesomeIcon*/}
                    {/*        className="discord_icon"*/}
                    {/*        icon={faDiscord}*/}
                    {/*    />*/}
                    {/*    <p>Войти через Discord</p>*/}
                    {/*</button>*/}
                    <p>
                        Продолжая авторизацию, вы соглашаетесь с{' '}
                        {<Link to="/offer">офертой</Link>} и <br />
                        {<Link to="/policy">политикой конфиденциальности</Link>}
                    </p>
                    {pathname !== '/auth' && (
                        <>
                            <p>
                                укажите Email, если вы хотите получить чек об
                                оплате
                            </p>
                            <div className="email_input">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </form>
            </div>
            <div className="side_image">
                <img src={currentImage} alt="side img" />
            </div>
        </>
    );
}
