/** @format */

import PaymentHistoryTable from './components/PaymentHistoryTable';
import LoginButton from './components/LoginButton';
import UserSubscription from './components/UserSubscription';
import { useEffect } from 'react';
import { userAPI } from '../../config/api.ts';
import { useLocalStorage, useTitle } from '../../utlis/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileInfo } from './userSlice';
import { Navigate } from 'react-router-dom';
import Header from '../../components/Header.jsx';

export default function UserPage() {
    useTitle('User');
    const stateUser = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [user, setUser] = useLocalStorage('user', null);

    useEffect(() => {
        if (stateUser?.id) setUser(stateUser);
    }, [stateUser, setUser]);

    useEffect(() => {
        if (user?.id) {
            userAPI.subscribersPayments({ userId: user.id }).then((res) => {
                if (
                    res?.payment_order_history &&
                    res?.active_subscription &&
                    res?.next_payment_date
                )
                    dispatch(
                        setProfileInfo({
                            paymentOrderHistory: res.payment_order_history,
                            activeSubscription: res.active_subscription,
                            nextPaymentDate: res.next_payment_date,
                        })
                    );
            });
        }
    }, [user, dispatch]);

    return user?.id || stateUser?.id ? (
        <div className="account_page">
            <Header />
            <div className="top_row">
                <UserSubscription user={user} />
                <LoginButton user={user} setUser={setUser} />
            </div>
            <div className="bottom_row">
                <PaymentHistoryTable user={user} />
            </div>
        </div>
    ) : (
        <Navigate to="/auth" />
    );
}
