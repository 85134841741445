/** @format */
import Slider from 'react-slick'; // Импортируем компонент слайдера
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import fcu_front_photo from '../../../static/images/fcu_front.png';
import fcu_back_photo from '../../../static/images/fcu_back.png';
import fcu_front_photo_2 from '../../../static/images/fcu_front_2.png';
import fcu_back_photo_2 from '../../../static/images/fcu_back_2.png';
import mcdu_front from '../../../static/images/mcdu_front.jpg';
import mcdu_back from '../../../static/images/mcdu_back.jpg';
import fms_front from '../../../static/images/fms_front.jpg';
import fms_back from '../../../static/images/fms_back.jpg';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CustomPrevArrow = ({ onClick }) => (
    <button
        onClick={onClick}
        style={{
            position: 'absolute',
            left: '-10px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '50%',
            width: '35px',
            height: '35px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
            zIndex: 2,
        }}
    >
        <ChevronLeft size={20} />
    </button>
);

const CustomNextArrow = ({ onClick }) => (
    <button
        onClick={onClick}
        style={{
            position: 'absolute',
            right: '-10px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '50%',
            width: '35px',
            height: '35px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
            zIndex: 2,
        }}
    >
        <ChevronRight size={20} />
    </button>
);

const ProductCard = ({ images, price, buyLink, name }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    return (
        <div
            style={{
                maxWidth: '300px',
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
            }}
        >
            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{name}</p>

            <Slider {...settings}>
                {images.map((img, index) => (
                    <div key={index}>
                        <img
                            src={img}
                            alt={`product-image-${index}`}
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            }}
                        />
                    </div>
                ))}
            </Slider>

            <div style={{ marginTop: '20px' }}>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                    {price} руб
                </p>
                <a href={buyLink} style={{ textDecoration: 'none' }}>
                    <button
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#000',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            fontSize: '16px',
                        }}
                    >
                        Купить
                    </button>
                </a>
            </div>
        </div>
    );
};

export default function Products() {
    const FCUproductImages = [
        fcu_front_photo,
        fcu_front_photo_2,
        fcu_back_photo,
        fcu_back_photo_2,
    ];
    const MCDUproductImages = [mcdu_front, mcdu_back];
    const FMSproductImages = [fms_front, fms_back];

    const FCUproductPrice = '29990';
    const MCDUproductPrice = '29990';
    const FMSproductPrice = '29990';
    const FCUproductLink =
        'https://vk.com/market/product/fcu-a320-ot-winwing-178803952-10011479';
    const MCDUproductLink =
        'https://vk.com/market/product/mcdu-a320-ot-winwing-178803952-10230642';
    const FMSproductLink =
        'https://vk.com/market/product/fms-b737-ot-winwing-178803952-10230625';

    const FCUName = 'FCU A320';
    const MCDUName = 'MCDU A320';
    const FMSName = 'FMS B737';

    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
        >
            <ProductCard
                images={FCUproductImages}
                price={FCUproductPrice}
                buyLink={FCUproductLink}
                name={FCUName}
            />
            <ProductCard
                images={MCDUproductImages}
                price={MCDUproductPrice}
                buyLink={MCDUproductLink}
                name={MCDUName}
            />
            <ProductCard
                images={FMSproductImages}
                price={FMSproductPrice}
                buyLink={FMSproductLink}
                name={FMSName}
            />
        </div>
    );
}
