/** @format */

import NotFoundPage from './NotFoundPage';

export default function ErrorPage({ code = '404' }) {
    const getErrorComponentByCode = () => {
        if (code === '404') return <NotFoundPage />;
    };
    return getErrorComponentByCode();
}
