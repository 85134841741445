/** @format */

import Card from './Card';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDiscord,
    faTelegram,
    faVk,
} from '@fortawesome/free-brands-svg-icons';

export default function Cards() {
    return (
        <div className="cards_container">
            <Card
                cardType={'1'}
                title="Доступен в VK, Telegram и Discord"
                descriptionList={[
                    'Кроссплатформенность — наш конёк!',
                    'Пользуйтесь ботом где угодно и на чём угодно.',
                ]}
            />
            <Card
                cardType={'2'}
                title="Быстрый ответ"
                descriptionList={[
                    'Находите всю необходимую информацию',
                    'для совершения виртуального полёта',
                    'за считанные секунды',
                ]}
            />
            <div className="card card3">
                <div className="card3_title">
                    <h5>Максимально просто</h5>
                    <p>
                        Всего 3 действия для вашего <br /> полета мечты
                    </p>
                </div>
                <h3>
                    написать <br /> заполнить <br /> полететь
                </h3>
            </div>
            <div className="card card4">
                <h5>Попробуйте прямо сейчас</h5>
                <div className="icons">
                    <Link to="https://vk.me/metar_bot">
                        <FontAwesomeIcon icon={faVk} />
                    </Link>
                    <Link to="https://t.me/flight_helper_bot">
                        <FontAwesomeIcon icon={faTelegram} />
                    </Link>
                    <Link to="https://discord.com/oauth2/authorize?client_id=727484772775100487&permissions=8&scope=applications.commands%20bot">
                        <FontAwesomeIcon icon={faDiscord} />
                    </Link>
                </div>
            </div>
        </div>
    );
}
