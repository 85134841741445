/** @format */

import PaymentHistoryRow from './PaymentHistoryRow';
import { Empty } from 'antd';

export default function PaymentHistoryTable({ user }) {
    return (
        <div className="payment_history_container">
            <div className="payment_header">
                <h1>История платежей</h1>
                <div className="payment_header_text">
                    <span>#</span>
                    <span>Наименование</span>
                    <span>Дата платежа</span>
                    <span>Цена</span>
                </div>
            </div>
            <div className="payments">
                {user?.paymentOrderHistory?.length > 0 ? (
                    user?.paymentOrderHistory?.map(
                        ({ description, payment_datetime, amount }, index) => (
                            <PaymentHistoryRow
                                id={index + 1}
                                name={description}
                                date={payment_datetime}
                                price={amount}
                            />
                        )
                    )
                ) : (
                    <Empty description="Нет данных" />
                )}
            </div>
        </div>
    );
}
